@tailwind base;
@tailwind components;
@tailwind utilities;

/* Source Serif Pro */

@font-face {
  font-family: "SourceSerifPro";
  src: url("./fonts/SourceSerifPro/SourceSerifPro-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "SourceSerifPro";
  src: url("./fonts/SourceSerifPro/SourceSerifPro-Regular.ttf")
    format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "SourceSerifPro";
  src: url("./fonts/SourceSerifPro/SourceSerifPro-SemiBold.ttf")
    format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "SourceSerifPro";
  src: url("./fonts/SourceSerifPro/SourceSerifPro-Bold.ttf") format("truetype");
  font-weight: 700;
}

/* Inter */

@font-face {
  font-family: "Inter";
  src: url("./fonts/Inter/Inter-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Inter";
  src: url("./fonts/Inter/Inter-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Inter";
  src: url("./fonts/Inter/Inter-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Inter";
  src: url("./fonts/Inter/Inter-Bold.ttf") format("truetype");
  font-weight: 700;
}

.overlay-gradient {
  background: linear-gradient(
    180deg,
    rgba(31, 43, 46, 0) 0%,
    rgba(31, 43, 46, 0.00838519) 7.8%,
    rgba(31, 43, 46, 0.0324148) 14.14%,
    rgba(31, 43, 46, 0.0704) 19.26%,
    rgba(31, 43, 46, 0.120652) 23.37%,
    rgba(31, 43, 46, 0.181481) 26.7%,
    rgba(31, 43, 46, 0.2512) 29.47%,
    rgba(31, 43, 46, 0.328119) 31.91%,
    rgba(31, 43, 46, 0.410548) 34.24%,
    rgba(31, 43, 46, 0.4968) 36.67%,
    rgba(31, 43, 46, 0.585185) 39.44%,
    rgba(31, 43, 46, 0.674015) 42.77%,
    rgba(31, 43, 46, 0.7616) 46.88%,
    rgba(31, 43, 46, 0.846252) 52%,
    rgba(31, 43, 46, 0.926281) 58.35%,
    #1f2b2e 66.15%
  );
}

html,
body,
#__next {
  height: 100%;
}
